<template>
    <interior-page-content-container
      nav-selection="toDo"
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="false"
      :loading="loading"
      :notificationMessage.sync="notificationMessage"
      :notificationType="notificationType"
      :notificationTime="notificationTime"
    >

      <template #title>
        To Do
      </template>

      <template #content>
        <div class="flex flex-col self-center py-2">
          <p v-if="projectsList.length == 0" class="px-16 mt-20 text-content">
            Create your first project to have access to your project specific todos.
            <a @click="$router.push({name: 'ProjectCreate'})" class="underline cursor-pointer">
              Create</a>
            your first one!
          </p>
          <div
            v-for="project in projectsList" :key="`project-id-${project.id}`"
            class="text-content"
          >
            <to-do-project-component
              :name="project.name"
              :image="project.picture.url"
              @click="toProjectItems(project.id)"
            />
          </div>
        </div>
      </template>

    </interior-page-content-container>
</template>

<script>
import { mapProjectFields } from '@/store/mappers';
import { PROJECTS_ALL, USER_WALKTHROUGH_DISPATCH } from '@/store/actions';
import { USER_WALKTHROUGH_STATE } from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ToDoProjectComponent from '@/components/toDo/ToDoProjectComponent.vue';

export default {
  name: 'ToDo',
  components: {
    InteriorPageContentContainer,
    ToDoProjectComponent,
  },
  data() {
    return {
      loading: true,
      notificationMessage: '',
      notificationType: 'success',
      notificationTime: 5000,
    };
  },
  computed: {
    ...mapProjectFields(['projectsList']),
    walkthroughStage() {
      return this.$store.getters[USER_WALKTHROUGH_STATE];
    },
  },
  watch: {
    walkthroughStage() {
      this.onWalkthrough();
    },
    notificationMessage(newVal) {
      if (newVal.length === 0 && this.$store.getters[USER_WALKTHROUGH_STATE] === 23) {
        this.$router.push({ name: 'Dashboard' });
      }
    },
  },
  created() {
    this.$store.dispatch(PROJECTS_ALL)
      .catch(() => this.$router.push({ name: 'PageNotFound' }))
      .finally(() => {
        this.loading = false;
        this.onWalkthrough();
      });
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters[USER_WALKTHROUGH_STATE] < 100) {
      if (to.name === 'Dashboard') {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
      } else {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
      }
    }
    next();
  },
  methods: {
    onWalkthrough() {
      if (this.$store.getters[USER_WALKTHROUGH_STATE] === 23) {
        this.notificationTime = 5000;
        this.notificationType = 'success';
        this.notificationMessage = 'This is where you can view all of your To Dos';
      }
    },
    backNav() {
      this.$router.go(-1);
    },
    toProjectItems(id) {
      this.$router.push({
        name: 'ProjectWishlist',
        params: { project_id: id },
      });
    },
  },
};
</script>

<style>

</style>
