<template>
  <div
    @click="$emit('click')"
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      rounded-md cursor-pointer w-72 mt-4 md:w-80 lg:w-96 bg-backfill h-16
      to-do-project-component
    `"
  >
    <div class="flex flex-col items-start justify-between w-2/3 pt-2 pb-1 pl-2">
        <p class="mb-2 font-bold text-left text-primary">{{name}}</p>
        <p class="text-xs text-content">View To Do Items</p>
    </div>

    <div
      class="w-1/3 h-full overflow-hidden bg-center bg-cover"
      :style="`background-image: url('${image}');`"
    ></div>

  </div>
</template>

<script>
export default {
  name: 'ToDoProjectComponent',
  props: {
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
};
</script>
